<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('multichannel.title.append_article')">
            <div v-if="!loading" class="px-3 py-2">
                <b-card
                    v-if="article"
                    border-variant="primary"
                    align="center"
                >
                    <b-card-text>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('article.column.id') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ article.id }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('article.column.sku') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ article.sku }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('article.column.name') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ article.name }}</b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="channelArticles"
                                      columnsPrefix="multichannel.column."
                                      :customSource="getThis()"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :items="myProvider"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :selectedSource="selectedSource"
                            >
                                <template v-slot:top-actions>
                                    <label class="mt-4">{{ $t('multichannel.text.select_articles') }}</label>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'AppendArticleModal',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                api_store_id: null,
                api_source_id: null,
                listing: 'default',
                ids: [],
            },
            form: {},
            article: {},
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Multichannel', ['getStores']),
        ...mapGetters('Multichannel', ['getArticlesTable']),
        ...mapGetters('Articles', ['getArticle']),
        shown() {
            const article = this.$store.dispatch('Articles/fetchArticle', this.payload.article_id)
            Promise.all([article])
                .then(() => {
                    this.article = this.getArticle()
                    this.$set(this, 'selected', [])
                    this.form = JSON.parse(JSON.stringify(this.defaultForm))
                    this.form.api_source_id = this.payload.source_id
                    this.form.api_store_id = this.payload.store_id

                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Multichannel/fetchArticles', 'getArticlesTable');
        },
        ctxAdditionalParams() {
            return {
                'api_source_id':  this.form.api_source_id,
                'api_store_id':  this.form.api_store_id,
                'article_id':  0,
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.form.ids = this.selected.map(row => row.id)
                    this.loading = true

                    let sendData = this.getSelectedData('articlesUnassigned')
                    this.$store.dispatch('Multichannel/assign', {
                        ...{
                            article_id: this.payload.article_id,
                            listing: this.form.listing,
                        },
                        ...sendData
                    })
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>